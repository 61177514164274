/* Styling for containers */
.container-triple-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 20px;
    padding: 10px;
}

/* Styling for content */
.content-triple-section {
    flex-basis: 33%; /* Setiap konten mendapat 1/3 dari lebar */
    padding: 10px;
}

.content-triple-section-unikL {
    flex-basis: 30%; /* Setiap konten mendapat 1/3 dari lebar */
    padding: 10px;
}

.content-triple-section-unikR {
    flex-basis: 70%; /* Setiap konten mendapat 1/3 dari lebar */
    padding: 10px;
}

.content-triple-section.left, .content-triple-section.center, .content-triple-section.right {
    padding: 10px;
}

.additional-info {
    transform: translate(0px, 210vh);
  }
 
.img-container-tween{
padding: 10px;
flex-basis: 50%;
display: flex;
}  
/* Media query for smaller screens */
@media (max-width: 768px) {
    .additional-info {
        transform: translate(0px, 0px);
      }
    .container-triple-section {
        display: block; /* Set full width for smaller screens */
    }
    .content-triple-section,.content-triple-section-unikL,.content-triple-section-unikR {
        flex-basis: 100%; /* Set full width for smaller screens */
    }
    .img-container-tween{
        flex-basis: 100%; /* Set full width for smaller screens */
        display: block;
    }
}

/* SlideShow */
.splide__slide img {
    width: 100%;
    height: auto;
  }


