.container-triple-section {
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  padding: 10px;
  display: flex;
}

.content-triple-section {
  flex-basis: 33%;
  padding: 10px;
}

.content-triple-section-unikL {
  flex-basis: 30%;
  padding: 10px;
}

.content-triple-section-unikR {
  flex-basis: 70%;
  padding: 10px;
}

.content-triple-section.left, .content-triple-section.center, .content-triple-section.right {
  padding: 10px;
}

.additional-info {
  transform: translate(0, 210vh);
}

.img-container-tween {
  flex-basis: 50%;
  padding: 10px;
  display: flex;
}

@media (width <= 768px) {
  .additional-info {
    transform: translate(0);
  }

  .container-triple-section {
    display: block;
  }

  .content-triple-section, .content-triple-section-unikL, .content-triple-section-unikR {
    flex-basis: 100%;
  }

  .img-container-tween {
    flex-basis: 100%;
    display: block;
  }
}

.splide__slide img {
  width: 100%;
  height: auto;
}

/*# sourceMappingURL=index.057fd514.css.map */
